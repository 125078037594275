import Vue from 'vue'
import VodohodSearchWidget from './VodohodSearchWidget.vue'
import SearchContent from './components/SearchContent.vue'
import Loader from './components/Loader.vue'
import Dialogs from './components/Dialogs.vue'
import vuetify from './plugins/vuetify'
import utils from './utils/utils.js'

Vue.config.productionTip = false
Vue.prototype.$utils = utils

// Global components
Vue.component('search-content', SearchContent)
Vue.component('loader', Loader)
Vue.component('dialogs', Dialogs)

// Templates
const widgetNode = document.querySelector('#vodohod-search-widget');
window.templatePath = widgetNode.getAttribute('template-name') || 'default'

Vue.component('search-filter', function (resolve) {
  // ����������� ��������� require ������ Webpack
  // ������������� ��������� ������ �� �����
  // ��� ����������� ����������� ��������
  require(['@/components/templates/' + window.templatePath + '/SearchFilter.vue'], resolve)
})

Vue.component('cruise-price-table', function (resolve) {
  require(['@/components/templates/' + window.templatePath + '/CruisePriceTable.vue'], resolve)
})

Vue.component('search-item-details-page', function (resolve) {
  require(['@/components/templates/' + window.templatePath + '/SearchItemDetailsPage.vue'], resolve)
})

Vue.component('search-item', function (resolve) {
  require(['@/components/templates/' + window.templatePath + '/SearchItem.vue'], resolve)
})

new Vue({
  vuetify,
  render: h => h(VodohodSearchWidget)
}).$mount('#vodohod-search-widget')
