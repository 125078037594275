// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../fonts/DINPro.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../fonts/DINPro-Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../fonts/DINPro-CondensedMedium.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../fonts/DINPro-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../fonts/DINPro-CondensedRegular.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, ":root{--vsw-accent-color-1:#0c2241;--vsw-accent-color-2:#2c3e50;--vsw-color-luxury:#beb086;--vsw-color-premium:#002642;--vsw-color-comfort:#9a243d;--vsw-color-silver:#ebebeb;--vsw-color-beige:#f2f0df;--vsw-color-wildlife:#003857}@font-face{font-family:DIN Pro;src:local(\"DIN Pro\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");font-weight:400;font-display:swap}@font-face{font-family:DIN Pro;src:local(\"DIN Pro Medium\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");font-weight:500;font-display:swap}@font-face{font-family:DIN Pro;src:local(\"DIN Pro Condensed Medium\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\");font-weight:600;font-display:swap}@font-face{font-family:DIN Pro;src:local(\"DIN Pro Bold\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\");font-weight:700;font-display:swap}@font-face{font-family:DIN Pro Cond;src:local(\"DIN Pro Condensed Medium\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff\");font-weight:400}", ""]);
// Exports
module.exports = exports;
