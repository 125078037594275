<template>
  <div ref="el">
    <slot/>
    <span></span>
  </div>
</template>

<script>
import tippy from 'tippy.js';
// import 'tippy.js/dist/tippy.css';

export default {
  name: 'new-tooltip',
  props: {
    text: {
      default: '',
      type: String,
    },
  },
  mounted() {
    const container = document.querySelector('#vsw-widget');
    tippy(this.$refs.el, {
      content: this.text,
      placement: 'bottom',
      theme: 'light',
      arrow: false,
      appendTo: container ? container : document.body,
    });
  }
}
</script>

