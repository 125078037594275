<template>
  <transition name="fade-in-500ms">
    <div 
      class="vsw-loader"
      v-show="show" 
      :is-full-screen="isFullScreen"
      :is-absolute="isAbsolute"
      :with-backgound="withBackgound"
      :is-filter-loader="isFilterLoader"
    >
      <div class="vsw-loader__container">
        <div class="vsw-loader__container__title" v-if="!isFilterLoader">
          ЗАГРУЗКА ДАННЫХ
        </div>
        <v-progress-circular
          :color="state.componentProps.accentBgColor1"
          indeterminate
          rounded
          height="4"
          width="2"
        ></v-progress-circular>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    state: Object,
    show: Boolean,
    isFullScreen: Boolean,
    isAbsolute: Boolean,
    withBackgound: Boolean,
    isFilterLoader: Boolean,
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss">
.vsw-loader {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  z-index: 10;
  pointer-events: auto;
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    margin: 64px 0;
    &__title {
      font-size: 24px;
      margin-bottom: 12px;
      color: var(--vsw-accent-bg-color-1);
    }
  }
  &[with-backgound] {
    background-color: #fff;
    padding: 32px;
  }
  &[with-backgound][is-filter-loader] {
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(2px);
    padding: 32px;
    z-index: 100;
    .vsw-loader__container {
      margin: 0;
      height: 100%;
    }
  }
  &[is-full-screen] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #fff;
    z-index: 10;
  }
  &[is-absolute] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    .vsw-loader__container {
      margin: 0;
    }
  }
}
</style>