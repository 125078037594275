export default {
  formatNum (num) {
    return num.toLocaleString()
  },
  formattedPrice (price) {
    return this.formatNum(Number(price).toFixed(0))
  },
  formattedRoutePath (list, options = {}) {
    let symbol = '';
    if (!options.noSymbol) {
      symbol = options.symbol || '→'
    }
    let res = '';
    const separator = `<span class="vsw-item__route-separator">${symbol}</span>`
    if (options.key) {
      res = list
        .map(item => item[options.key])
        .join(separator)
    }
    else {
      res = list
        .join(separator)
    }

    if (options.noSymbol) {
      return res
    } else {
      return (res + separator);
    }
  }
}
