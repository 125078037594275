var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vsw-content",class:{'vsv-content-main' : _vm.isMainPage},attrs:{"env":_vm.env}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.state.loaders.content && !_vm.state.loaders.main && !_vm.state.filterDataChanged),expression:"!state.loaders.content && !state.loaders.main && !state.filterDataChanged"}]},[_c('div',{staticClass:"search-result-ref"}),_c('div',{staticClass:"vsw-content__title"},[_vm._v(" Результаты поиска ")]),_c('div',{staticClass:"vsw-content__toolbar"},[_c('div',{staticClass:"vsw-content__toolbar__info"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.state.componentProps.accentBgColor2}},[_vm._v(" mdi-magnify ")]),_c('div',[_vm._v(" Найдено круизов: "+_vm._s(_vm.cruiseItemCountTotal)+" ")])],1),(_vm.cruiseItemCountTotal > 0)?_c('div',{staticClass:"vsw-content__toolbar__sort"},[_c('div',{staticStyle:{"position":"relative"}},[_c('v-select',{attrs:{"items":_vm.state.apiData.SORT,"label":"Сортировать по параметру","return-object":"","item-text":"NAME","item-value":"KEY","filled":"","hide-details":"","background-color":"#ffffff","color":_vm.state.componentProps.accentBgColor1,"item-color":_vm.state.componentProps.accentBgColor1,"menu-props":{ offsetY: true, nudgeBottom: '12px' },"attach":".sort-input-element"},on:{"change":function($event){_vm.handleChangeSort({
              event: $event,
              params: _vm.getApiFilterParams()
            })}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
return [_c('v-layout',{attrs:{"align-center":""}},[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.getSortItemIcon(item)))]),_vm._v(" "+_vm._s(item.NAME)+" ")],1)]}},{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('v-layout',[_c('v-icon',{staticClass:"mr-4"},[_vm._v(_vm._s(_vm.getSortItemIcon(item)))]),_vm._v(" "+_vm._s(item.NAME)+" "),_c('v-spacer'),(item.KEY === _vm.sortSelectedItems.sort.KEY)?_c('v-icon',{attrs:{"color":_vm.state.componentProps.accentBgColor1}},[_vm._v("mdi-check-box-outline ")]):_vm._e()],1)]}}],null,false,3779591900),model:{value:(_vm.sortSelectedItems.sort),callback:function ($$v) {_vm.$set(_vm.sortSelectedItems, "sort", $$v)},expression:"sortSelectedItems.sort"}}),_c('span',{staticClass:"sort-input-element",staticStyle:{"position":"absolute","top":"0","left":"0"}})],1)]):_vm._e()]),(!_vm.state.loaders.content)?_c('div',{staticClass:"vsw-content__item-container"},_vm._l((_vm.state.apiData.CRUISES),function(item,index){return _c('search-item',{key:'cruise-search-item-' + index,attrs:{"item":item,"state":_vm.state,"getApiAssetUrl":_vm.getApiAssetUrl,"openShipDetailsPage":_vm.openShipDetailsPage,"openCruiseDetailsPage":_vm.openCruiseDetailsPage,"handleSearchItemFeedbackBtn":_vm.handleSearchItemFeedbackBtn}})}),1):_vm._e(),(_vm.cruiseItemCountTotal > 0)?_c('div',{staticClass:"vsw-content__pagination"},[_c('div',{staticClass:"vsw-content__pagination__title"},[_vm._v(" Показывается круизов: "+_vm._s(_vm.cruiseItemCountShown)+" из "+_vm._s(_vm.cruiseItemCountTotal)+" ")]),(_vm.cruiseItemCountShown !== _vm.cruiseItemCountTotal)?_c('div',{staticClass:"vsw-content__pagination__wrap"},[_c('div',{staticClass:"vsw-content__pagination__wrap__buttons"},[_c('button',{staticClass:"\n              vsw-widget__btn-1\n              vsw-widget__btn-1--small\n            ",on:{"click":function($event){_vm.loadAllCruises({
              params: _vm.getApiFilterParams()
            })}}},[_vm._v(" Загрузить все "+_vm._s(_vm.cruiseItemCountTotal)+" ")])]),_c('div',[_c('v-pagination',{staticClass:"vsw-content__pagination__pages",attrs:{"length":_vm.pagesCount,"total-visible":_vm.state.breakpoints.UpSM ? 9 : 5,"color":_vm.state.componentProps.accentBgColor1},on:{"input":_vm.handleInputPagination},model:{value:(_vm.state.page),callback:function ($$v) {_vm.$set(_vm.state, "page", $$v)},expression:"state.page"}})],1)]):_vm._e()]):_vm._e()]),_c('loader',{attrs:{"state":_vm.state,"show":_vm.state.loaders.content}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }