<template>
  <div class="vsw-content" :env="env" :class="{'vsv-content-main' : isMainPage}">
    <div v-show="!state.loaders.content && !state.loaders.main && !state.filterDataChanged">
      <div class="search-result-ref"></div>
      <div class="vsw-content__title">
        Результаты поиска
      </div>
      <div class="vsw-content__toolbar">
        <div class="vsw-content__toolbar__info">
          <v-icon
            class="mr-2"
            :color="state.componentProps.accentBgColor2"
          >
            mdi-magnify
          </v-icon>
          <div>
            Найдено круизов: {{ cruiseItemCountTotal }}
          </div>
        </div>
        <div class="vsw-content__toolbar__sort" v-if="cruiseItemCountTotal > 0">
          <div
            style="position: relative;"
          >
            <v-select
              :items="state.apiData.SORT"
              v-model="sortSelectedItems.sort"
              label="Сортировать по параметру"
              @change="handleChangeSort({
                event: $event,
                params: getApiFilterParams()
              })"
              return-object
              item-text="NAME"
              item-value="KEY"
              filled
              hide-details
              background-color="#ffffff"
              :color="state.componentProps.accentBgColor1"
              :item-color="state.componentProps.accentBgColor1"
              :menu-props="{ offsetY: true, nudgeBottom: '12px' }"
              attach=".sort-input-element"
            >
              <template v-slot:selection="{ item }">
                <v-layout align-center>
                  <v-icon class="mr-4">{{ getSortItemIcon(item) }}</v-icon>
                  {{ item.NAME }}
                </v-layout>
              </template>
              <template v-slot:item="{ item }">
                <v-layout>
                  <v-icon class="mr-4">{{ getSortItemIcon(item) }}</v-icon>
                  {{ item.NAME }}
                  <v-spacer></v-spacer>
                  <v-icon
                    v-if="item.KEY === sortSelectedItems.sort.KEY"
                    :color="state.componentProps.accentBgColor1"
                  >mdi-check-box-outline
                  </v-icon>
                </v-layout>
              </template>
            </v-select>
            <!-- Dummy element for menu attachment -->
            <span
              class="sort-input-element"
              style="position: absolute; top: 0; left: 0"
            ></span>
          </div>
        </div>
      </div>
      <div class="vsw-content__item-container" v-if="!state.loaders.content">
        <search-item
          v-for="(item, index) in state.apiData.CRUISES" :key="'cruise-search-item-' + index"
          :item="item"
          :state="state"
          :getApiAssetUrl="getApiAssetUrl"
          :openShipDetailsPage="openShipDetailsPage"
          :openCruiseDetailsPage="openCruiseDetailsPage"
          :handleSearchItemFeedbackBtn="handleSearchItemFeedbackBtn"
        >
        </search-item>
      </div>
      <div class="vsw-content__pagination" v-if="cruiseItemCountTotal > 0">
        <div class="vsw-content__pagination__title">
          Показывается круизов: {{ cruiseItemCountShown }} из {{ cruiseItemCountTotal }}
        </div>
        <div class="vsw-content__pagination__wrap" v-if="cruiseItemCountShown !== cruiseItemCountTotal">
          <div class="vsw-content__pagination__wrap__buttons">
            <button
              class="
                vsw-widget__btn-1
                vsw-widget__btn-1--small
              "
              @click="loadAllCruises({
                params: getApiFilterParams()
              })"
            >
              Загрузить все {{ cruiseItemCountTotal }}
            </button>
          </div>
          <div>
            <v-pagination
              class="vsw-content__pagination__pages"
              v-model="state.page"
              :length="pagesCount"
              :total-visible="state.breakpoints.UpSM ? 9 : 5"
              :color="state.componentProps.accentBgColor1"
              @input="handleInputPagination"
            ></v-pagination>
          </div>
        </div>
      </div>
    </div>
    <loader
      :state="state"
      :show="state.loaders.content"
    />
  </div>
</template>

<script>

export default {
  props: {
    state: Object,
    loadPage: Function,
    getApiAssetUrl: Function,
    loadAllCruises: Function,
    handleChangeSort: Function,
    openShipDetailsPage: Function,
    openCruiseDetailsPage: Function,
    handleSearchItemFeedbackBtn: Function,
    getApiFilterParams: Function
  },
  data() {
    return {
      sortSelectedItems: {
        sort: {},
      }
    }
  },
  watch: {
    'state.apiData'() {
      this.setActiveSort()
    }
  },
  computed: {
    env() {
      return process.env.NODE_ENV
    },
    cruiseItemCountShown() {
      return Number(this?.state?.apiData?.CRUISES?.length)
    },
    cruiseItemCountTotal() {
      return Number(this?.state?.apiData?.ITEM_COUNT || 0)
    },
    pagesCount() {
      return Math.ceil(this.cruiseItemCountTotal / this.state.pageItemCount) || 0
    },
    isMainPage() {
      return window.location.pathname === '/'
    }
  },
  methods: {
    getSortItemIcon(item) {
      if (item.KEY.endsWith('asc')) {
        return 'mdi-sort-reverse-variant'
      } else if (item.KEY.endsWith('desc')) {
        return 'mdi-sort-variant'
      }
    },
    setActiveSort() {
      if (!this.state.itemDetailsPage.value && this.state.apiData.SORT) {
        this.sortSelectedItems.sort = this.state.apiData.SORT.find(item => item.ACTIVE)
      }
    },
    handleInputPagination(event) {
      this.loadPage({
        page: event,
        params: this.getApiFilterParams(),
        scrollToResult: true
      });
    }
  }
}
</script>

<style lang="scss">
@import "../styles/variables";

.vsw-content {
  padding: var(--vsw-search-page-padding);
  padding-top: 24px;

  .SM & {
    padding: 8px;
  }

  &__title {
    font-size: 22px;
    text-transform: uppercase;
  }

  &__toolbar {
    display: grid;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    grid-template-columns: 1fr 330px;

    .MD & {
      grid-template-columns: 1fr 280px;
    }

    .SM & {
      display: block;
    }

    &__info {
      font-size: 18px;
      display: flex;
      align-items: center;
      opacity: 0.7;

      .SM & {
        margin: 16px 0;
      }
    }

    &__sort {
      box-shadow: var(--vsw-item-shadow);
      border: 1px solid var(--vsw-border-color-1) !important;
      width: 100%;

      .XS & {
        max-width: 100%;
      }
    }
  }

  &__pagination {
    margin-top: 24px;
    padding: 24px;
    background-color: #fff;
    border: 1px solid var(--vsw-border-color-1);
    box-shadow: var(--vsw-item-shadow);

    &__wrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      // flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;

      .MD & {
        display: block;
      }

      &__buttons {
        min-width: min(400px, 100%);

        .MD & {
          margin-bottom: 16px;
        }
      }
    }

    &__pages {
      .v-pagination {
        justify-content: flex-end;

        .MD & {
          justify-content: start;
        }
      }
    }

    &__title {
      margin: 12px 0;
    }
  }
}
</style>
