<template>
  <v-app id="vsw-widget" v-if="state.authIsValid">
    <v-main class="vsw-widget" ref="container" :class="state.componentProps.templateName">
      <dialogs
        :state="state"
        :getApiAssetUrl="getApiAssetUrl"
        :updateApiData="updateApiData"
        :getApiUrl="getApiUrl"
        :openImageViewerDialog="openImageViewerDialog"
      />
      <loader
        :state="state"
        :show="state.loaders.main"
        :is-full-screen="true"
      />
      <v-fade-transition>
        <search-item-details-page
          v-if="state.itemDetailsPage.value"
          :state="state"
          :search="search"
          :clearSearch="clearSearch"
          :updateApiFilterData="updateApiFilterData"
          :loadPage="loadPage"
          :loadAllCruises="loadAllCruises"
          :handleChangeSort="handleChangeSort"
          :handleSearchItemFeedbackBtn="handleSearchItemFeedbackBtn"
          :openCruiseDetailsPage="openCruiseDetailsPage"
          :openShipDetailsPage="openShipDetailsPage"
          :getApiAssetUrl="getApiAssetUrl"
          :openYoutubeVideoViewerDialog="openYoutubeVideoViewerDialog"
          :openImageViewerDialog="openImageViewerDialog"
          :openShipTourViewer="openShipTourViewer"
          :fetchCruiseProgramData="fetchCruiseProgramData"
          :fetchCruiseTableApiData="fetchCruiseTableApiData"
          :fetchCruiseRoomsApiData="fetchCruiseRoomsApiData"
        />
      </v-fade-transition>
      <search-filter
        v-show="showSearchFilter"
        ref="searchFilter"
        :state="state"
        :search="search"
        :clearSearch="clearSearch"
        :updateApiFilterData="updateApiFilterData"
        :writeToLocalStorage="writeToLocalStorage"
        :fetchLocalStorageData="fetchLocalStorageData"
        :getApiFilterParams="getApiFilterParams"
        :setUrl="setUrl"
      />
      <search-content
        v-show="showSearchContent"
        :state="state"
        :getApiAssetUrl="getApiAssetUrl"
        :loadPage="loadPage"
        :loadAllCruises="loadAllCruises"
        :handleChangeSort="handleChangeSort"
        :openCruiseDetailsPage="openCruiseDetailsPage"
        :openShipDetailsPage="openShipDetailsPage"
        :handleSearchItemFeedbackBtn="handleSearchItemFeedbackBtn"
        :getApiFilterParams="getApiFilterParams"
      />
      <!-- Email notification -->
      <v-snackbar
        v-model="state.notifications.emailSent.value"
        :timeout="state.notifications.emailSent.options.timeout"
        bottom
        right
        multi-line
      >
        <v-icon class="mr-4">{{state.notifications.emailSent.options.icon}}</v-icon>
        {{ state.notifications.emailSent.options.text }}
        <template v-slot:action="{ attrs }">
          <v-btn
            :color="state.componentProps.accentTextColor1"
            v-bind="attrs"
            @click="state.notifications.emailSent.value = false"
            text
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import './styles/import.scss'
import './styles/fonts.scss'
const axios = require('axios')
const PATH = require('path')

const componentNodeDoc = document.querySelector('#vodohod-search-widget')
export default {
  name: 'VodohodSearchWidget',
  data () {
    return {
      state: {
        componentProps: {
          accentBgColor1: '#9a243d',
          accentBgColor2: '#3d4e67',
          accentTextColor1: '#fff',
          accentTextColor2: '#0c2241',
          mainBgColor: '#fff',
          searchFilterPanelBgColor: '#fff',
          searchFilterPanelBottomBgColor: '#fff',
          searchFilterPanelTextColor: '#333',
          searchFilterPanelBottomTextColor: '#757575',
          searchItemShadow: 'none',
          itemShadow: 'none',
          borderColor1: 'rgba(0, 0, 0, 0.1)',
          font1: 'Roboto',
          searchPagePadding: '24px',
          searchPageUrl: '',
          cruisePageUrl: '',
          shipPageUrl: '',
          miniLayout: false,
          searchInNewTab: false,
          isSinglePageApp: true,
          setUrlParams: true,
          templateName: 'default',
          searchOnInit: 'true',
          onFilterChange: () => {}
        },
        previousApiData: {},
        apiData: {
          FILTER: {
            ITEMS: {
              CITY_END: { NAME: '' },
              CITY_START: { NAME: '' },
              DATE_START: { NAME: '' },
              DAYS_COUNT_PERIODS: { NAME: '' },
              DESTINATION: { NAME: '' },
              IS_THEMATIC: { NAME: '' },
              LABEL_GIFT_CERTIFICATE: { NAME: '' },
              LABEL_WEEKEND: { NAME: '' },
              MIN_PRICE: { NAME: '' },
              MOTOR_SHIP: { NAME: '' },
              MOTOR_SHIP_CLASS: { NAME: '' },
              NAVIGATION_YEAR: { NAME: '' },
              ROOMS_AVAILABLE: { NAME: '' },
              ROUTE_CITIES: { NAME: '' },
              SPECIAL_OFFERS: { NAME: '' },
              THEME: { NAME: '' },
              WITH_FOREIGNERS: { NAME: '' }
            }
          }
        },
        locale: 'ru',
        authIsValid: true,
        showExtraParams: false,
        filterDataChanged: false,
        testSiteUrl: '',
        vodohodUrlBase: 'https://vodohod.com',
        apiUrlBase: process.env.VUE_APP_API_URL_BASE,
        apiSrcDevUrlBase: process.env.VUE_APP_API_SRC_DEV_URL_BASE,
        apiAuth: '',
        mailUrl: '/api/send_mail/',
        apiDataRequest: null,
        loaders: {
          main: false,
          content: false,
          filter: false,
          itemPageContent: false
        },
        pageItemCount: 20,
        datePickerMenu: false,
        timeAppMounted: new Date().getTime(),
        waitPeriodBeforeCanSendEmail: 3000,
        itemDetailsPage: {
          value: false,
          target: 'cruise',
        },
        notifications: {
          emailSent: {
            value: false,
            options: {
              timeout: 8000
            }
          },
        },
        selectedItem: {},
        cruiseData: {},
        shipData: {},
        emailFormIsValid: false,
        mediaDialog: {
          value: false,
          objectFit: 'cover',
          isDirectLink: false,
          isImage: false,
          isYoutubeVideo: false,
          selectedIndex: 0,
          items: []
        },
        shipTourDialog: {
          value: false,
          data: {
            link: ''
          }
        },
        cruiseProgramDetailsDialog: {
          value: false,
          loader: false,
          data: {
            title: '',
            description: '',
            city: '',
            visitors: [],
            selectedImageIndex: 0,
            featuresImages: [],
            images: [],
          }
        },
        emailDialog: {
          value: false,
          data: {
            userFullName: {
              value: '',
              label: 'Ваше ФИО *',
              isRequired: true
            },
            userPhoneNumber: {
              value: '+7',
              code: '7',
              label: 'Ваш номер телефона *',
              isRequired: true
            },
            userEmail: {
              value: '',
              label: 'Ваш email'
            },
            comment: {
              value: '',
              label: 'Комментарий'
            },
            route: {
              value: '',
              label: 'Маршрут круиза'
            },
            motor_ship: {
              value: '',
              label: 'Название теплохода'
            },
            date: {
              value: '',
              label: 'Дата'
            }
          }
        },
        page: 1,
        filterSelectedItems: {
          destination: [],
          dates: [],
          cityStart: [],
          cityEnd: [],
          routeCities: [],
          ships: [],
          shipClasses: [],
          specialOffers: [],
          navigationYear: [],
          daysCount: [],
          priceMin: {},
          priceMax: {},
          LABEL_WEEKEND: {},
          WITH_FOREIGNERS: {},
          ROOMS_AVAILABLE: {},
          IS_THEMATIC: {},
        },
        breakpoints: { // for replace $vuetify.breakpoint
          XXS: false,
          UpXXS: false,
          XS: false,
          UpXS: false,
          XSM: false,
          UpXSM: false,
          SM: false,
          UpSM: false,
          MD: false,
          UpMD: false,
          LG: false,
          UpLG: false,
        },
      },
    }
  },
  created () {
    this.setAuthData()
    this.setComponentProps()
    this.setComponentStyleVariables()
    this.handleWidgetCreated()
  },
  mounted () {
    if (!this.state.componentProps.miniLayout) {
      this.state.showExtraParams = true
    }
    this.initScrollListners()
    this.handleWindowsPopState()
    this.initResponsive()
  },
  watch: {
    'state.apiData' (newValue) {
      this.state.filterDataChanged = false
    }
  },
  computed: {
    isDevEnv () {
      return process.env.NODE_ENV !== 'production'
    },
    isLocalFileEnv () {
      return window.location.origin === 'file://'
    },
    isLocalhostEnv () {
      return ['localhost', '127.0.0.1'].includes(window.location.hostname)
    },
    cruisePageIsShown () {
      return this.state.itemDetailsPage.value === true &&
        this.state.itemDetailsPage.target === 'cruise'
    },
    showSearchFilter () {
      return !this.cruisePageIsShown
    },
    showSearchContent () {
      return !this.state.componentProps.miniLayout && !this.cruisePageIsShown
    },
  },
  methods: {
    handleWindowsPopState () {
      window.addEventListener('popstate', (event) => {
        if (this.state.componentProps.setUrlParams) {
          this.applyUrlParams({skipSetUrl: true})
        }
      })
    },
    initScrollListners () {
      let widgetContainerNode = document.querySelector('.vodohod-search-widget-container')
      if (widgetContainerNode) {
        widgetContainerNode.addEventListener('scroll', (event) => {
          if (this.state.datePickerMenu) {
            this.state.datePickerMenu = false
          }
        })
      }
      window.addEventListener('scroll', (event) => {
        if (this.state.datePickerMenu) {
          this.state.datePickerMenu = false
        }
      })
    },
    initResponsive () {
      const breakpoints = {
        XXS: 320,
        XS: 600,
        XSM: 800,
        SM: 960,
        MD: 1264,
        LG: 1904,
      }
      window.addEventListener('resize', (event) => {
        Object.keys(breakpoints).forEach((breakpoint) => {
          if (this.$el.clientWidth <= breakpoints[breakpoint]) {
            this.$refs.container.$el.classList.add(breakpoint)
            this.state.breakpoints[breakpoint] = true
            this.$refs.container.$el.classList.remove(`Up${breakpoint}`)
            this.state.breakpoints[`Up${breakpoint}`] = false
          } else {
            this.$refs.container.$el.classList.remove(breakpoint)
            this.state.breakpoints[breakpoint] = false
            this.$refs.container.$el.classList.add(`Up${breakpoint}`)
            this.state.breakpoints[`Up${breakpoint}`] = true
          }
        })
      })
      window.dispatchEvent(new Event('resize'))
    },
    setAuthData () {
      const url = window.location.hostname + window.location.pathname
      if (this.isDevEnv) {
        this.state.testSiteUrl = process.env.VUE_APP_SITE_PARAM || url
        this.state.apiAuth = process.env.VUE_APP_API_URL_AUTH
      }
      else if (this.isLocalFileEnv || this.isLocalhostEnv) {
        const componentNode = document.querySelector('#vodohod-search-widget')
        this.state.testSiteUrl = componentNode.getAttribute('test-site-url') || url
        this.state.apiAuth = componentNode.getAttribute('api-auth')
      }
      else {
        const componentNode = document.querySelector('#vodohod-search-widget')
        this.state.apiAuth = componentNode.getAttribute('api-auth') || ''
      }
    },
    setComponentProps () {
      const componentNode = document.querySelector('#vodohod-search-widget')
      if (process.env.NODE_ENV === 'development') {
        //this.setDevelopmentStyles()
        this.setProductionStyles(componentNode)
      }
      else if (process.env.NODE_ENV === 'production' && componentNode) {
        this.setProductionStyles(componentNode)
      }
    },
    setDevelopmentStyles () {
      // Перезаписываем дефолтные нейтральные стили в режиме разработки для тестирования
      // neutral-blue
      this.state.componentProps.accentBgColor1 = '#90a4ae'
      this.state.componentProps.searchFilterPanelBottomTextColor = '#757575'
      this.state.componentProps.mainBgColor = '#eceff1'
      this.state.componentProps.searchFilterPanelBgColor = '#eceff1'
      this.state.componentProps.searchFilterPanelBottomBgColor = '#fff'
      this.state.componentProps.searchItemShadow = `
        0 12px 36px rgba(0, 0, 0, 0.1),
        0 2px 6px rgba(0, 0, 0, 0.04)
      `
      this.state.componentProps.itemShadow = `
        0 12px 36px rgba(0, 0, 0, 0.1),
        0 2px 6px rgba(0, 0, 0, 0.04)
      `
    },
    setProductionStyles (componentNode) {
      const receivedProps = {
        accentBgColor1: componentNode.getAttribute('accent-bg-color-1'),
        accentBgColor2: componentNode.getAttribute('accent-bg-color-2'),
        accentTextColor1: componentNode.getAttribute('accent-text-color-1'),
        accentTextColor2: componentNode.getAttribute('accent-text-color-2'),
        mainBgColor: componentNode.getAttribute('main-bg-color'),
        searchFilterPanelBgColor: componentNode.getAttribute('search-filter-panel-bg-color'),
        searchFilterPanelBottomBgColor: componentNode.getAttribute('search-filter-panel-bottom-bg-color'),
        searchFilterPanelTextColor: componentNode.getAttribute('search-filter-panel-text-color'),
        searchFilterPanelBottomTextColor: componentNode.getAttribute('search-filter-panel-bottom-text-color'),
        searchItemShadow: componentNode.getAttribute('search-item-shadow'),
        itemShadow: componentNode.getAttribute('item-shadow'),
        borderColor1: componentNode.getAttribute('border-color-1'),
        font1: componentNode.getAttribute('font-1'),
        searchPagePadding: componentNode.getAttribute('search-page-padding'),
        searchPageUrl: componentNode.getAttribute('search-page-url'),
        cruisePageUrl: componentNode.getAttribute('cruise-page-url'),
        shipPageUrl: componentNode.getAttribute('ship-page-url'),
        miniLayout: (componentNode.getAttribute('mini-layout') === 'true'),
        searchInNewTab: (componentNode.getAttribute('search-in-new-tab') === 'true'),
        isSinglePageApp: (componentNode.getAttribute('is-single-page-app') === 'true'),
        setUrlParams: (componentNode.getAttribute('set-url-params') === 'true'),
        onFilterChange: componentNode.getAttribute('on-filter-change'),
        templateName: componentNode.getAttribute('template-name') || 'default',
        searchOnInit: componentNode.getAttribute('search-on-init') || 'true',
      }
      // Перезаписываем дефолтные свойства компонента
      for (const [key, value] of Object.entries(receivedProps)) {
        // Не менять условие! Атрибуты могут иметь значение false,
        // такие значения тоже должны применяться
        if (value !== null) {
          this.state.componentProps[key] = value
        }
      }
    },
    setComponentStyleVariables () {
      document.documentElement.style.setProperty('--vsw-accent-bg-color-1', this.state.componentProps.accentBgColor1)
      document.documentElement.style.setProperty('--vsw-accent-bg-color-2', this.state.componentProps.accentBgColor2)
      document.documentElement.style.setProperty('--vsw-accent-text-color-1', this.state.componentProps.accentTextColor1)
      document.documentElement.style.setProperty('--vsw-accent-text-color-2', this.state.componentProps.accentTextColor2)
      document.documentElement.style.setProperty('--vsw-main-bg-color', this.state.componentProps.mainBgColor)
      document.documentElement.style.setProperty('--vsw-search-filter-panel-bg-color', this.state.componentProps.searchFilterPanelBgColor)
      document.documentElement.style.setProperty('--vsw-search-filter-panel-bottom-bg-color', this.state.componentProps.searchFilterPanelBottomBgColor)
      document.documentElement.style.setProperty('--vsw-search-filter-panel-text-color', this.state.componentProps.searchFilterPanelTextColor)
      document.documentElement.style.setProperty('--vsw-search-filter-panel-bottom-text-color', this.state.componentProps.searchFilterPanelBottomTextColor)
      document.documentElement.style.setProperty('--vsw-search-item-shadow', this.state.componentProps.searchItemShadow)
      document.documentElement.style.setProperty('--vsw-item-shadow', this.state.componentProps.itemShadow)
      document.documentElement.style.setProperty('--vsw-border-color-1', this.state.componentProps.borderColor1)
      document.documentElement.style.setProperty('--vsw-font-1', this.state.componentProps.font1)
      document.documentElement.style.setProperty('--vsw-search-page-padding', this.state.componentProps.searchPagePadding)
    },
    getApiPath (action = 'base') {
      if (action === 'base') {
        return '/api/'
      }
      else if (action === 'mail') {
        return '/api/send_mail/'
      }
      else if (action === 'clearSearch') {
        return this.state.apiData.FILTER.LINKS.SEF_DEL_FILTER_URL
      }
      else if (action === 'search') {
        return this.state.apiData.FILTER.LINKS.SEF_SET_FILTER_URL
      }
    },
    getApiAssetUrl (url) {
      return `${this.state.apiUrlBase}${url}`
    },
    getApiUrl (action = 'base') {
      const urlPath = this.getApiPath(action)
      return `${this.state.apiUrlBase}${urlPath}`
    },
    getApiParam (param) {
      if (param === 'site') {
        if (this.isDevEnv || this.isLocalFileEnv || this.isLocalhostEnv) {
          return encodeURI(this.state.testSiteUrl)
        }
        else {
          return encodeURI(window.location.hostname + window.location.pathname)
        }
      }
    },
    updateApiData (payload) {
      return new Promise((resolve, reject) => {
        // Cancel previous API data request if exists
        this.apiDataRequest?.cancel('cancel-api-data-request')
        // Creates apiDataRequest token for upcomming ajax (overwrite the previous one)
        this.apiDataRequest = axios.CancelToken.source()

        try {
          this.setAuthData()
        }
        catch (error) {}

        const deafultOptions = {
          getFilterDataOnly: false,
          showContentLoader: false,
          showFilterLoader: false,
          showMainLoader: false,
          showItemPageContentLoader: false,
          setApiData: true,
          setApiFilterData: false,
          setApiCruiseData: false,
          setApiShipData: false
        }
        payload.options = {...deafultOptions, ...payload.options}
        if (payload.options.getFilterDataOnly) {
          payload.params.ajax = 'y'
        }
        this.state.loaders.content = payload.options.showContentLoader
        this.state.loaders.filter = payload.options.showFilterLoader
        this.state.loaders.main = payload.options.showMainLoader
        this.state.loaders.itemPageContent = payload.options.showItemPageContentLoader
        axios.get(payload.url, {
          headers: {
            Authorization: this.state.apiAuth
          },
          cancelToken: this.apiDataRequest.token,
          params: {
            site: this.getApiParam('site'),
            ...payload.params
          }
        })
          .then(response => {
            this.state.previousApiData = this.state.apiData
            if (payload.options.setApiData) {
              this.state.apiData = response.data
            }
            if (payload.options.setApiFilterData) {
              this.state.apiData.FILTER = response.data.FILTER
            }
            if (payload.options.setApiCruiseData) {
              this.state.cruiseData = response.data
            }
            if (payload.options.setApiShipData) {
              this.state.apiData = response.data.CATALOG
              this.state.shipData = response.data
            }
            if (payload.options.showContentLoader) {
              this.state.loaders.content = false
            }
            if (payload.options.showFilterLoader) {
              this.state.loaders.filter = false
            }
            if (payload.options.showMainLoader) {
              this.state.loaders.main = false
            }
            if (payload.options.showItemPageContentLoader) {
              this.state.loaders.itemPageContent = false
            }
            resolve(response.data)
          })
          .catch((error) => {
            if (error?.message === 'cancel-api-data-request') {
              return
            }
            else {
              console.log(error)
              this.state.authIsValid = false
              return
            }
          })
      })
    },
    execWidgetCallback (params) {
      try {
        window['onSearchFilterChange'](params.params)
      }
      catch (error) {}
    },
    getCruiseTableDataUrl (id) {
      return this.state.apiSrcDevUrlBase + `/${this.state.locale}/cruises/room-tariffs/${id}?with=decks/svgScheme`
    },
    getCruiseProgramDataUrl (program) {
      return this.state.apiUrlBase + `/local/include/modals/excursion.php?id=${program.ID}&excursion[price]=${program.PRICE || 0}&excursion[priceChildren]=${program.PRICE_CHILDREN || 0}&excursion[requestForExcursion]=${program.REQUEST_FOR_EXCURSION || 0}&excursion[replaces]=${program.REPLACES || 0}&excursion[forSaleOnWebsite]=${program.FOR_SALE_ON_WEBSITE || 0}`
    },
    getCruiseRoomsDataUrl (id) {
      return this.state.apiSrcDevUrlBase + `/${this.state.locale}/booking/rooms/${id}?with=roomClass,roomClass/media,deck/svgScheme&filter[availability][0]=1`
    },
    async handleWidgetCreated () {
      this.applyUrlParams({firstLoad: true})
    },
    async handleWidgetInit () {
      const condtition1 = !this.state.componentProps.miniLayout && this.state.componentProps.searchOnInit === 'true';
      const condtition2 = this.state.componentProps.searchPageUrl && ~window.location.pathname.indexOf(this.state.componentProps.searchPageUrl);
      if (condtition1 || condtition2) {
        const localParamsData = this.fetchLocalStorageData('vsw_params')
        if (localParamsData) {
          await this.search(localParamsData.params, {showMainLoader: true})
        }
        else {
          await this.updateApiData({
            url: this.getApiUrl(),
            /*params: {
              set_filter: 'y',
            },*/
            options: {
              showMainLoader: true,
              setApiFilterData: true
            }
          })
        }
      }
      else {
        await this.updateApiData({
          url: this.getApiUrl(),
          /*params: {
            set_filter: 'y'
          },*/
          options: {
            showMainLoader: true,
            setApiFilterData: true
          }
        })
      }
    },
    async fetchCruiseTableApiData (id) {
      return new Promise((resolve, reject) => {
        this.updateApiData({
          url: this.getCruiseTableDataUrl(id),
          options: {
            setApiData: false,
          }
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async fetchCruiseRoomsApiData (id) {
      return new Promise((resolve, reject) => {
        this.updateApiData({
          url: this.getCruiseRoomsDataUrl(id),
          options: {
            setApiData: false,
          }
        })
          .then((data) => {
            resolve(data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    },
    async fetchCruiseProgramData (program) {
      this.updateApiData({
        url: this.getCruiseProgramDataUrl(program),
        options: {
          setApiData: false,
        }
      })
        .then((data) => {
          let htmlNode = document.createElement('html')
          htmlNode.innerHTML = data
          let modalNode = htmlNode.querySelector('.b-modal')
          let modalTitleText = modalNode.querySelector('.card-m__title').innerHTML
          let modalDescriptionTextBlock = modalNode.querySelector('.card-m-description__text p');
          let modalDescriptionText = "";
          if (!!modalDescriptionTextBlock) {
            modalDescriptionText = modalNode.querySelector('.card-m-description__text p').innerHTML
          }
          let imgPaths = []
          let featuresImgData = []
          let visitors = []
          let city = modalNode.querySelector('.card-m-subtitle__row div').innerHTML
          modalNode.querySelectorAll('.card-m-slider__slide img').forEach(imgNode => {
            imgPaths.push(imgNode.attributes.src.value)
          })
          modalNode.querySelectorAll('.card-m-subtitle__options .tooltip').forEach(node => {
            let iconNode = node.querySelector('.tooltip__icon img')?.attributes?.src?.value
            let textNode = node.querySelector('.tooltip__text')?.innerHTML.replace('\n', '')
            featuresImgData.push({
              icon: iconNode,
              text: textNode,
            })
          })
          visitors.push({
            icon: modalNode.querySelector('.card-m-subtitle__group-size img')?.attributes?.src?.value,
            text: modalNode.querySelector('.card-m-subtitle__group-size span').innerHTML,
          })
          this.state.cruiseProgramDetailsDialog.selectedImageIndex = 0
          this.state.cruiseProgramDetailsDialog.data.title = modalTitleText
          this.state.cruiseProgramDetailsDialog.data.description = modalDescriptionText
          this.state.cruiseProgramDetailsDialog.data.city = city
          this.state.cruiseProgramDetailsDialog.data.visitors = visitors
          this.state.cruiseProgramDetailsDialog.data.featuresImages = featuresImgData
          this.state.cruiseProgramDetailsDialog.data.images = imgPaths
          this.state.cruiseProgramDetailsDialog.loader = false
        })
    },
    async updateApiFilterData (params) {
      if (this.state.componentProps.miniLayout) {
        this.updateApiData({
          url: this.getApiUrl(),
          params: {
            set_filter: 'y',
            ...params
          },
          options: {
            showFilterLoader: true
          }
        })
      }
      else {
        let response = await this.search(params, {showFilterLoader: true})
        let filterParams = {...response, ...{filterParams: this.getApiFilterParams()}}
        this.execWidgetCallback({callbackName: 'onFilterChange', params: filterParams})
      }
    },
    async search (params, options = {}) {
      if (this.state.componentProps.miniLayout || (window.location.pathname === '/' && this.state.componentProps.templateName === 'aeroflot')) {
        if (this.state.componentProps.searchPageUrl) {
          this.setRoute(this.state.componentProps.searchPageUrl)
        }
        if (this.state.componentProps.isSinglePageApp) {
          this.state.showExtraParams = true
        }
        this.state.componentProps.miniLayout = false
      }
      this.state.page = 1
      return await this.updateApiData({
        url: this.getApiUrl(),
        params: {
          set_filter: 'y',
          ...params
        },
        options: {
          showContentLoader: true,
          ...options
        }
      })
    },
    clearSearch () {
      this.state.page = 1
      this.updateApiData({
        url: this.getApiUrl('clearSearch'),
        params: {},
        options: {
          showContentLoader: true
        }
      })
    },
    loadAllCruises (payload) {
      this.state.page = 1
      this.updateApiData({
        url: this.getApiUrl(),
        params: {
          set_filter: 'y',
          ...payload.params
        },
        options: {
          showContentLoader: true
        }
      })
    },
    loadPage (payload) {
      this.updateApiData({
        url: this.getApiUrl(),
        params: {
          set_filter: 'y',
          PAGEN_1: payload.page,
          ...payload.params
        },
        options: {
          showContentLoader: true
        }
      }).then(() => {
        if (payload.scrollToResult) {
          const contentBlock = document.querySelector('.vsw-content__toolbar');
          if (contentBlock) {
            contentBlock.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
          }
        }
      })
    },
    handleChangeSort (payload) {
      this.updateApiData({
        url: this.getApiUrl(),
        params: {
          set_filter: 'y',
          sort: payload.event.KEY,
          order: payload.event.ORDER,
          ...payload.params
        },
        options: {
          showContentLoader: true
        }
      })
    },
    getDetailsPageUrl (pageName, item) {
      let id = ''
      if (pageName === 'cruise') {
        id = item.DETAIL_PAGE_URL.split('?').find(param => param.startsWith('id')).replace('id=', '')
      }
      else if (pageName === 'ship') {
        id = item.MOTOR_SHIP.DETAIL_LINK.split('?').find(param => param.startsWith('id')).replace('id=', '')
      }
      return `${location.origin}/?page=${pageName}&id=${id}`
    },
    setDetailsPageUrl (params) {
      let {pageName, item, skipSetUrl} = params
      if (!skipSetUrl) {
        this.setUrl({
          page: pageName,
          id: item.ID,
        })
      }
    },
    openCruiseDetailsPage (cruise, skipSetUrl) {
      if (this.state.componentProps.cruisePageUrl) {
        let urlParams = new URLSearchParams(location.search)
        if (!urlParams.get('id')) {
          const cruiseUrlArray = cruise['DETAIL_PAGE_URL'].split('?')
          const cruiseUrlParams = new URLSearchParams(cruiseUrlArray[1]);
          if (cruiseUrlParams.get('id')) {
            urlParams.set('id', cruiseUrlParams.get('id'));
            urlParams.set('page', 'cruise')
            window.location = `${this.state.componentProps.cruisePageUrl}?${urlParams.toString()}`;
          }
        }
      }
      this.updateApiData({
        url: this.state.apiUrlBase + cruise.DETAIL_PAGE_URL,
        options: {
          setApiData: false,
          setApiCruiseData: true,
          showMainLoader: true
        }
      })
      .then((data) => {
        if (!skipSetUrl) {
          this.setUrl({
            page: 'cruise',
            id: data.ID,
          })
        }

        this.state.cruiseData = data
        this.state.itemDetailsPage.target = 'cruise'
        this.state.itemDetailsPage.value = true

        setTimeout(() => {
          window.scrollTo({top: 0})
        }, 100)
      })
    },
    extractIdFromUrlString (url) {
      return url?.split('?').find(param => param.startsWith('id')).replace('id=', '')
    },
    openShipDetailsPage (ship, skipSetUrl) {
      let detailPageUrlFromShipApi = ship?.MOTOR_SHIP?.DETAIL_LINK || ship?.DETAIL_PAGE_URL
      let extractedId = this.extractIdFromUrlString(detailPageUrlFromShipApi)
      let id = ship?.MOTOR_SHIP?.ID || extractedId
      let detailPageUrl = detailPageUrlFromShipApi || `/api/ships/?id=${id}`

      if (this.state.componentProps.shipPageUrl) {
        let urlParams = new URLSearchParams(location.search)
        if (urlParams.get('page') !== 'ship') {
          urlParams.set('id', id);
          urlParams.set('page', 'ship')
            window.location = `${this.state.componentProps.shipPageUrl}?${urlParams.toString()}`;
        }
      }

      this.updateApiData({
        url: this.state.apiUrlBase + detailPageUrl,
        options: {
          setApiData: false,
          setApiShipData: true,
          showMainLoader: true
        }
      })
      .then((data) => {
        if (!skipSetUrl) {
          this.setUrl({
            page: 'ship',
            shipName: data.SHIP_DETAIL.CODE,
            id
          })
        }
        this.setShipFilter()
        this.state.cruiseData = data
        this.state.itemDetailsPage.target = 'ship'
        this.state.itemDetailsPage.value = true

        setTimeout(() => {
          window.scrollTo({top: 0})
        }, 100)
      })
    },
    setShipFilter () {
      let shipItem = this.state.apiData.FILTER.ITEMS.MOTOR_SHIP.VALUES[0]
      this.$refs.searchFilter.setShipFilter(shipItem)
    },
    writeToLocalStorage (payload) {
      localStorage.setItem(payload.key, JSON.stringify(payload.data))
    },
    fetchLocalStorageData (key) {
      const data = localStorage.getItem(key)
      try {
        return JSON.parse(data)
      }
      catch (error) {}
    },
    handleSearchItemFeedbackBtn (item) {
      this.state.selectedItem = item
      this.setEmailDialogData(item)
      this.state.emailDialog.value = true
    },
    setEmailDialogData (item) {
      this.state.emailDialog.data.route.value = item.ROUTE_CITY
        ? item.ROUTE_CITY.map(listItem => listItem.NAME).join(' - ')
        : item.ROUTE_CITIES_NAMES.join(' - ')
      this.state.emailDialog.data.motor_ship.value = item.MOTOR_SHIP.NAME
      this.state.emailDialog.data.date.value = `${item.DATE_START} - ${item.DATE_END} (${item.DAYS_COUNT})`
    },
    setRoute (route) {
      let urlParams = new URLSearchParams(location.search)
      if (this.state.componentProps.isSinglePageApp) {
        if (route === this.state.componentProps.searchPageUrl) {
          route = '/'
        }
        window.history.pushState('', '', PATH.join('/', this.state.componentProps.searchPageUrl, route, urlParams.toString()))
      }
      else {
        if (this.state.componentProps.searchInNewTab) {
          window.open(route)
        }
        else {
          window.location.pathname = route
        }
      }
    },
    openShipTourViewer (shipTourUrl) {
      this.state.shipTourDialog.data.link = this.state.vodohodUrlBase + shipTourUrl
      this.state.shipTourDialog.value = true
    },
    openImageViewerDialog (props) {
      this.state.mediaDialog.isDirectLink = props.isDirectLink
      this.state.mediaDialog.objectFit = props.objectFit
      this.state.mediaDialog.selectedIndex = props.index
      this.state.mediaDialog.items = this.filterImageItems(props)
      this.state.mediaDialog.type = 'image'
      this.state.mediaDialog.value = true
    },
    openYoutubeVideoViewerDialog (props) {
      this.state.mediaDialog.isDirectLink = props.isDirectLink
      this.state.mediaDialog.objectFit = props.objectFit
      this.state.mediaDialog.selectedIndex = props.index
      this.state.mediaDialog.items = this.filterImageItems(props)
      this.state.mediaDialog.type = 'youtube'
      this.state.mediaDialog.value = true
    },
    filterImageItems (props) {
      if (props.key) {
        return props.items.map(item => item[props.key])
      }
      else {
        return props.items
      }
    },
    getApiFilterParams () {
      const params = {}
      for (const [key, value] of Object.entries(this.state.filterSelectedItems)) {
        if (Array.isArray(value)) {
          value.forEach(item => {
            if (key === 'dates') {
              params[item.CONTROL_NAME] = item.VALUE
            }
            else if (item.HTML_VALUE) {
              params[item.CONTROL_NAME] = item.HTML_VALUE
            }
            else {
              params[item.CONTROL_NAME] = item.VALUE
            }
          })
        }
        else if (typeof value === 'object' && value !== null) {
          if (value.HTML_VALUE) {
            params[value.CONTROL_NAME] = value.HTML_VALUE
          }
          else if (value.VALUE) {
            params[value.CONTROL_NAME] = value.VALUE
          }
        }
      }
      return params
    },
    setUrl (params) {
      if (!this.state.componentProps.setUrlParams) {return}
      // Filter out undefined keys
      params = Object.fromEntries(
        Object.entries(params).filter(([key, value]) => key !== 'undefined')
      )
      let urlParams = new URLSearchParams(params).toString()
      // Set URL, if it's not the same as the current URL to avoid creating duplicates in history
      if (window.location.search !==  `?${urlParams}`) {
        history.pushState(params, null, `?${urlParams}`)
      }
    },
    getCurrentPageParam () {
      let page = 'main'
      if (this.state.itemDetailsPage.value) {
        page = this.state.itemDetailsPage.target
      }
      return {page}
    },
    getUrlParams () {
      let urlParams = Object.fromEntries(new URLSearchParams(location.search))
      // Filter out undefined keys
      urlParams = Object.fromEntries(
        Object.entries(urlParams).filter(([key, value]) => key !== 'undefined')
      )
      return urlParams
    },
    async applyUrlParams (params) {
      let {skipSetUrl, firstLoad} = params
      let urlParams = this.getUrlParams()
      // Change page
      if (this.state.componentProps.miniLayout && !urlParams.page) {
        await this.handleWidgetInit()
      }
      else if (urlParams.page === 'main' || !urlParams.page) {
        this.state.itemDetailsPage.value = false
        this.state.componentProps.miniLayout = false
        this.state.showExtraParams = true
        // Fetch params
        if (firstLoad) {
          await this.handleWidgetInit()
        }
        else {
          await this.updateApiFilterData(urlParams)
        }
      }
      // Note: when loading content pages (cruise, ships, etc), if it will be require to load
      // this.updateApiFilterData in the future, then run the page method only after
      // this.updateApiFilterData is resolved so the data does not get overwritten in race conditions
      else if (urlParams.page === 'cruise') {
        this.openCruiseDetailsPage({
          DETAIL_PAGE_URL: `/api/cruise/?id=${urlParams.id}`
        }, skipSetUrl)
      }
      else if (urlParams.page === 'ship') {
        this.openShipDetailsPage({
          DETAIL_PAGE_URL: `/api/ships/?id=${urlParams.id}`,
        }, skipSetUrl)
      }
    },
  }
}
</script>

<style lang="scss">
#vsw-widget {
  overflow: initial !important;
}

$cols: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
@each $col in $cols {
  .UpXS .col-sm-#{$col} {
    flex: 0 0 (100 / 12 * $col) * 1%;
    min-width: (100 / 12 * $col) * 1%;
  }
}
@each $col in $cols {
  .UpSM .col-md-#{$col} {
    flex: 0 0 (100 / 12 * $col) * 1%;
    min-width: (100 / 12 * $col) * 1%;
  }
}
@each $col in $cols {
  .UpMD .col-lg-#{$col} {
    flex: 0 0 (100 / 12 * $col) * 1%;
    min-width: (100 / 12 * $col) * 1%;
  }
}
.v-application--wrap {
  min-height: unset !important;
}
.theme--light.v-application {
  background: var(--vsw-main-bg-color) !important;
  height: 100%;
  width: 100%;
}
.v-input--selection-controls.v-input--checkbox {
  .v-label.v-label--is-disabled {
    opacity: 0.4;
  }
}
.vsw-clickable-img {
  cursor: pointer !important;
}
.vsw-widget {
  font-family: var(--vsw-font-1), Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--vsw-accent-color-1);
  height: 100%;
  width: 100%;
  background-color: var(--vsw-main-bg-color) !important;
  &__text-btn {
    cursor: pointer;
    &:hover {
      color: #9a243d;
    }
  }
  &__btn-1 {
    color: var(--vsw-accent-bg-color-1);
    cursor: pointer;
    // width: 100%;
    height: 56px;
    padding-right: 16px;
    padding-left: 16px;
    border: 2px solid var(--vsw-accent-bg-color-1);
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
    &[is-disabled] {
      background-color: rgba(0, 0, 0, 0.2);
      color: rgba(0, 0, 0, 0.4);
      border: none;
    }
    &[no-padding] {
      padding: 0;
    }
  }
  &__btn-1--small {
    font-size: 14px;
    height: 42px;
    .XS & {
      font-size: 14px;
      margin-right: 0;
      margin-left: 0;
    }
    .XSS & {
      font-size: 10px;
    }
  }
  &__btn-1--x-small {
    font-size: 12px;
    height: 36px;
  }
  &__btn-1--fill {
    color: var(--vsw-accent-text-color-1);
    background-color: var(--vsw-accent-bg-color-1);
  }
  &__btn-1--text {
    border: none;
    color: var(--vsw-accent-bg-color-1) !important;
  }
}
.vsw-email-dialog {
  &__description {
    color: rgba(0, 0, 0, 0.8);
    &__title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 8px;
    }
    &__label {
      & span:first-child {
        font-weight: 600;
        margin-bottom: 8px;
      }
    }
  }
}
.fade-in-500ms-enter-active {
  transition: all 0.0s ease;
}
.fade-in-500ms-leave-active {
  transition: all 0.5s ease;
}
.fade-in-500ms-enter {
  transition: all 0.0s ease;
  opacity: 0;
}
.fade-in-500ms-leave-to {
  transition: all 0.5s ease;
  opacity: 0;
}
</style>
